import {
	Checkbox,
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Textarea,
	useBreakpointValue,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { GetNotificationDto, NotificationApi, UpdateNotificationRequest } from '../../../clients';
import Button from '../../../components/Button';
import FormControl from '../../../components/Form/FormControl';
import FormLabel from '../../../components/Form/FormLabel';
import Input from '../../../components/Input';
import Text from '../../../components/Text';
import { getApiAuthConfig } from '../../../services/api.service';

interface EditModalProps {
	isOpen: boolean;
	onClose: () => void;
	imageUrl: string;
	row: GetNotificationDto;
	getAllNotifications(): Promise<void>;
}

const EditModal: React.FC<EditModalProps> = ({ isOpen, onClose, imageUrl, row, getAllNotifications }) => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const notificationApi = new NotificationApi(apiConfig);
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [changesIsLoading, setChangesIsLoading] = React.useState<boolean>(false);
	const [imageError, setImageError] = React.useState<boolean>(false);

	const handleSaveChanges = async (values: {
		title: string;
		description: string;
		isPopup: boolean;
		isBell: boolean;
		actionLink: string;
	}): Promise<void> => {
		setChangesIsLoading(true);
		try {
			const updateNotificationRequest: UpdateNotificationRequest = {
				id: row.id,
				updateNotificationRequestDto: {
					bellEnabled: values.isBell,
					popupEnabled: row.url ? values.isPopup : undefined,
					title: values.isBell ? values.title : undefined,
					description: values.isBell ? values.description : undefined,
					actionLink: values.actionLink,
				},
			};
			await notificationApi.updateNotification(updateNotificationRequest);
			addToast('Notificação alterada com sucesso.', {
				appearance: 'success',
				autoDismiss: true,
			});
			getAllNotifications();
		} catch (error) {
			addToast('Erro ao tentar editar a notificação.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setChangesIsLoading(false);
			onClose();
		}
	};

	React.useEffect(() => {
		const img = new window.Image();
		img.src = imageUrl;

		img.onload = () => setIsLoading(false);
		img.onerror = () => {
			setIsLoading(false);
			setImageError(true);
		};
	}, [imageUrl]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='3xl'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Notificação</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Formik
						initialValues={{
							title: row.title || '',
							description: row.description || '',
							isPopup: row.popup_enabled || false,
							isBell: row.bell_enabled || false,
							actionLink: row.action_link || '',
						}}
						onSubmit={handleSaveChanges}
					>
						{({ values, handleChange, handleSubmit }) => (
							<Form onSubmit={handleSubmit}>
								<Flex direction='column' mb={4}>
									<Flex justifyContent='space-around' width='100%' mb={2}>
										{row.url && (
											<Checkbox id='notification-modal-popup-checkbox' name='isPopup' isChecked={values.isPopup} onChange={handleChange}>
												Adicionar como Popup
											</Checkbox>
										)}
										<Checkbox id='notification-modal-bell-checkbox' name='isBell' isChecked={values.isBell} onChange={handleChange}>
											Adicionar como Notificação
										</Checkbox>
									</Flex>
								</Flex>
								<Flex direction={isMobile ? 'column' : 'row'} align={isMobile ? 'center' : 'flex-start'} justify='space-between'>
									{values.isPopup && row.url && (
										<Flex direction='column' align='center' flex='1' mr={4} justify='center' mb={4}>
											<Flex align='center' justify='center' height='100%'>
												{isLoading ? (
													<Spinner />
												) : imageError ? (
													<Text id='notification-edit-modal-image-text'>Imagem indisponível</Text>
												) : (
													<Image src={imageUrl} maxHeight='300px' maxWidth='300px' />
												)}
											</Flex>
											{row.action_link && (
												<Flex direction='column' align='center' mt={2}>
													<FormControl id='notification-modal-form-action-link'>
														<FormLabel id='notification-edit-modal-action-link-form-label'>Link de Ação</FormLabel>
														<Input
															id='notification-modal-action-link'
															name='actionLink'
															value={values.actionLink}
															onChange={handleChange}
															placeholder='Insira o link de ação'
														/>
													</FormControl>
												</Flex>
											)}
										</Flex>
									)}
									{values.isBell && (
										<Flex direction='column' flex='2'>
											<FormControl mt={4} id='notification-modal-form-title'>
												<FormLabel id='notification-title-form-label'>Título</FormLabel>
												<Input id='notification-modal-title' name='title' value={values.title} onChange={handleChange} />
											</FormControl>
											<FormControl mt={4} id='notification-modal-form-description'>
												<FormLabel id='notification-edit-modal-description-form-label'>Descrição</FormLabel>
												<Textarea
													id='notification-modal-description'
													name='description'
													value={values.description}
													onChange={handleChange}
												/>
											</FormControl>
										</Flex>
									)}
								</Flex>
								<ModalFooter>
									<Button id='notification-modal-return-button' bgColor='gray' onClick={onClose} mr='10px'>
										Voltar
									</Button>
									<Button
										id='notification-modal-submit-button'
										isLoading={changesIsLoading}
										isDisabled={changesIsLoading}
										bgColor='primary'
										color='white'
										type='submit'
									>
										Alterar
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default EditModal;
