import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { translations } from '../translations';

export const defaultRenderer = (value, translatedProp, maskFunction, renderChanges, checkIfResultIsArray) => {
	if (checkIfResultIsArray(value)) {
		const olderValue =
			value.length && value[0] !== null && value[0] !== ''
				? maskFunction && typeof maskFunction === 'function'
					? maskFunction(value[0])
					: typeof value[0] === 'boolean'
					? value[0]
						? 'Sim'
						: 'Não'
					: value[0]
				: 'Sem dados';

		const newValue =
			value.length === 1
				? [
						maskFunction && typeof maskFunction === 'function'
							? maskFunction(value[0])
							: typeof value[0] === 'boolean'
							? value[0]
								? 'Sim'
								: 'Não'
							: value[0],
				  ]
				: value
						.slice(1)
						.filter((item) => item !== null && item !== '')
						.map((item) =>
							maskFunction && typeof maskFunction === 'function'
								? maskFunction(item)
								: typeof item === 'boolean'
								? item
									? 'Sim'
									: 'Não'
								: item
						);

		if (newValue.length === 0 || (newValue.length === 1 && newValue[0] === olderValue)) {
			return (
				<Flex alignItems='center'>
					<Flex>
						<Flex fontWeight='bold' pr='10px'>
							{translatedProp}:{' '}
						</Flex>
						<Flex>{olderValue}</Flex>
					</Flex>
				</Flex>
			);
		}

		return (
			<Flex alignItems='center'>
				<Flex>
					<Flex fontWeight='bold' pr='10px'>
						{translatedProp}:{' '}
					</Flex>
					<Flex>{olderValue}</Flex>
				</Flex>
				{newValue.map((value, index) => (
					<Flex alignItems='center' key={index}>
						<Flex px='10px'>
							<FaArrowRight />
						</Flex>
						{value}
					</Flex>
				))}
			</Flex>
		);
	}

	if (typeof value === 'object' && value !== null) {
		return (
			<Flex flexDirection='column'>
				<Flex fontWeight='bold'>{translatedProp}:</Flex>
				{Object.keys(value).map((subProp) => {
					const subValue = value[subProp];

					if (checkIfResultIsArray(subValue)) {
						return renderChanges(subValue, subProp);
					}

					if (typeof subValue === 'object' && subValue !== null) {
						return (
							<Flex key={subProp}>
								<Flex>{translations[subProp]?.value || subProp}:</Flex>
								{Object.keys(subValue).map((innerProp) => {
									const innerValue = subValue[innerProp];

									if (checkIfResultIsArray(innerValue)) {
										return renderChanges(innerValue, innerProp);
									}

									return (
										<Flex key={innerProp}>
											<Flex>
												{translations[innerProp]?.value || innerProp}: {innerValue}
											</Flex>
										</Flex>
									);
								})}
							</Flex>
						);
					} else {
						return (
							<Flex key={subProp}>
								<Flex>
									{translations[subProp]?.value || subProp}: {subValue}
								</Flex>
							</Flex>
						);
					}
				})}
			</Flex>
		);
	}

	return (
		<Flex>
			<Flex>
				{translatedProp}: {value}
			</Flex>
		</Flex>
	);
};
