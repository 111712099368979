/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface GetSellerChangesRequest {
    documentId: string;
    limit: number;
    currentPage: number;
    collectionName: Array<GetSellerChangesCollectionNameEnum>;
}

/**
 * 
 */
export class SellersChangesHistoryApi extends runtime.BaseAPI {

    /**
     * Retorna o histórico de alterações do seller.
     */
    async getSellerChangesRaw(requestParameters: GetSellerChangesRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling getSellerChanges.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getSellerChanges.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getSellerChanges.');
        }

        if (requestParameters.collectionName === null || requestParameters.collectionName === undefined) {
            throw new runtime.RequiredError('collectionName','Required parameter requestParameters.collectionName was null or undefined when calling getSellerChanges.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.collectionName) {
            queryParameters['collection_name'] = requestParameters.collectionName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/sellers-changes-history/{document_id}`.replace(`{${"document_id"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Retorna o histórico de alterações do seller.
     */
    async getSellerChanges(requestParameters: GetSellerChangesRequest): Promise<PaginatedDto> {
        const response = await this.getSellerChangesRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetSellerChangesCollectionNameEnum {
    Sellers = 'sellers',
    SellersCredentials = 'sellers_credentials',
    SellerRisks = 'seller_risks'
}
