import { absorb_costs, accreditationStatus, seller_assignment, seller_status, type_account } from '../../../../config/constants';
import {
	maskCnae,
	maskCns,
	maskCpfOrCnpj,
	maskFullDate,
	maskMoneyToReal,
	maskPhoneNumber,
	maskZipcode,
	formatToBrazilianTimezone,
} from '../../../../services/masks.service';
import { ClassificationTranslations } from '../enums/classificationTranslations';

export const translations = {
	name: { value: 'Nome', type: 'Data' },
	description: { value: 'Descrição', type: 'Data' },
	document: { value: 'CPF/CNPJ', type: 'Data', function: maskCpfOrCnpj },
	state_registration: { value: 'Registro Estadual', type: 'Data' },
	code: { value: 'Chave do Estabelecimento', type: 'Data' },
	settlement_type: { value: 'Tipo de Liquidação', type: 'Configurations' },
	address: { value: 'Endereço', type: 'Data' },
	number: { value: 'Número', type: 'Data' },
	street: { value: 'Rua', type: 'Data' },
	complement: { value: 'Complemento', type: 'Data' },
	district: { value: 'Bairro', type: 'Data' },
	state: { value: 'Estado', type: 'Data' },
	city: { value: 'Cidade', type: 'Data' },
	zipcode: { value: 'CEP', type: 'Data', function: maskZipcode },
	bank_account: { value: 'Data Bancários', type: 'Data' },
	bank_code: { value: 'Código do Banco', type: 'Data' },
	agency_number: { value: 'Número da Agência', type: 'Data' },
	agency_digit: { value: 'Dígito da Agência', type: 'Data' },
	account_number: { value: 'Número da Conta', type: 'Data' },
	account_digit: { value: 'Digito da Conta', type: 'Data' },
	type_account: {
		value: 'Tipo de Conta',
		type: 'Data',
		function: (value) => {
			const item = type_account.find((type) => type.value === value);
			return item ? item.text : value;
		},
	},
	pix_key: { value: 'Chave Pix', type: 'Data' },
	monthly_billing: { value: 'Faturamento Mensal', type: 'Data', function: maskMoneyToReal },
	covenant: { value: 'Convênio', type: 'Data' },
	phone: { value: 'Telefone', type: 'Data', function: maskPhoneNumber },
	secondary_phone: { value: 'Telefone Secundário', type: 'Data', function: maskPhoneNumber },
	tertiary_phone: { value: 'Telefone Terciário', type: 'Data', function: maskPhoneNumber },
	status: {
		value: 'Status',
		type: 'Data',
		function: (value) => {
			const item = seller_status.find((status) => status.value === value);
			return item ? item.text : value;
		},
	},
	pix_pos: { value: 'Pix POS', type: 'Configurations' },
	billet_provider: { value: 'Provedor de Boleto', type: 'Configurations' },
	pix_provider: { value: 'Provedor de Pix', type: 'Configurations' },
	is_assignor: { value: 'Cedente do Boleto', type: 'Configurations' },
	has_split_rules: { value: 'Regras de Divisão', type: 'Configurations' },
	cns: { value: 'CNS', type: 'Data', function: maskCns },
	cnae: { value: 'CNAE', type: 'Data', function: maskCnae },
	assignment: {
		value: 'Atribuição',
		type: 'Data',
		function: (value) => {
			const item = seller_assignment.find((assignment) => assignment.key === value);
			return item ? item.text : value;
		},
	},
	owner_name: { value: 'Nome do Proprietário', type: 'Data' },
	owner_document: { value: 'Documento do Proprietário', type: 'Data', function: maskCpfOrCnpj },
	erp: { value: 'Sistema de gestão (ERP)', type: 'Data' },
	absorb_costs: {
		value: 'Absorver Custos',
		type: 'Configurations',
		function: (value) => {
			const item = absorb_costs.find((cost) => cost.value === value);
			return item ? item.text : value;
		},
	},
	no_payment_methods: {
		value: 'Pagamentos Não Utilizados',
		type: 'Configurations',
		function: (value) => {
			const item = absorb_costs.find((cost) => cost.value === value);
			return item ? item.text : value;
		},
	},
	risk_classification: { value: 'Riscos', type: 'Risks' },
	lawsuits: { value: 'Processos Judiciais', type: 'Risks' },
	risk_active: { value: 'Antifraude', type: 'Configurations' },
	negative_media: { value: 'Mídia Negativa', type: 'Risks' },
	classification: {
		value: 'Risco',
		type: 'Risks',
		function: (value) => {
			return ClassificationTranslations[value] || value;
		},
	},
	note: { value: 'Observação', type: 'Risks' },
	attachments: { value: 'Anexos', type: 'Risks' },
	min_risk: { value: 'Mínimo Para Antifraude', type: 'Configurations', function: maskMoneyToReal },
	risk_value: { value: 'Valor Antifraude', type: 'Configurations', function: maskMoneyToReal },
	three_ds_active: { value: '3Ds Ativo', type: 'Configurations' },
	min_three_ds: { value: 'Valor mínimo 3Ds', type: 'Configurations', function: maskMoneyToReal },
	three_ds_value: { value: 'Valor 3Ds', type: 'Configurations', function: maskMoneyToReal },
	celcoin_value: { value: 'Taxa Pagamento de Contas', type: 'Configurations', function: maskMoneyToReal },
	celcoin_fee_type: {
		value: 'Tipo Taxa Pagamento de Contas',
		type: 'Configurations',
		function: (type) => {
			const feeTypeMap = {
				real: 'Real',
				percentage: 'Porcentagem',
			};
			return feeTypeMap[type] || type;
		},
	},
	card_payer_check: { value: 'Validar Nome no Link', type: 'Configurations' },
	payment_provider: { value: 'Provedor de Crédito', type: 'Configurations' },
	pos_android_cancel: { value: 'Cancelamento no Terminal', type: 'Configurations' },
	pos_android_absorb_costs: { value: 'Venda Standalone', type: 'Configurations' },
	card_payer_check_min_value: { value: 'Mínimo Para Validar Nome no Link', type: 'Configurations', function: maskMoneyToReal },
	extract_name: { value: 'Identificação Extrato (cartão)', type: 'Configurations' },
	document_ofac: { value: 'CNPJ OFAC', type: 'Data' },
	document_onu: { value: 'CNPJ ONU', type: 'Data' },
	document_rf: { value: 'CNPJ RF', type: 'Data' },
	email: { value: 'E-mail', type: 'Data' },
	secondary_email: { value: 'E-mail Secundário', type: 'Data' },
	tertiary_email: { value: 'E-mail Terciário', type: 'Data' },
	owner_document_pep: { value: 'CPF PEP', type: 'Data' },
	owner_document_ofac: { value: 'CPF OFAC', type: 'Data' },
	owner_document_onu: { value: 'CPF ONU', type: 'Data' },
	owner_document_ref: { value: 'CPF REF', type: 'Data' },
	owner_birth: { value: 'Data de Nascimento', type: 'Data', function: formatToBrazilianTimezone },
	updated_at: { value: 'Data de Atualização', type: 'Data', function: maskFullDate },
	update_at: { value: 'Data de Atualização', type: 'Data', function: maskFullDate },
	frontier: { value: 'Fronteiriça', type: 'Data' },
	accreditation_status: {
		value: 'Status do credenciamento',
		type: 'Data',
		function: (status) => {
			const item = accreditationStatus.find((item) => item.value === status);
			return item ? item.text : status;
		},
	},
	merchant_id: { value: 'Merchant', type: 'Configurations' },
	responsibles: { value: 'Responsáveis', type: 'Data' },
	sale_plan_id: { value: 'Plano de Venda', type: 'SalePlan' },
	acquirers: { value: 'Adquirente', type: 'Data' },
	logo_id: { value: 'Logo', type: 'Data' },
	theme_id: { value: 'Tema', type: 'Data' },
	billet_code: { value: 'Código do Boleto', type: 'Configurations' },
	inactivity_alert: { value: 'Alertar Inatividade', type: 'Configurations' },
	cost_by_split_active: { value: 'Cobrança de Divisão', type: 'Configurations' },
	cost_by_split: { value: 'Valor de Cobrança de Divisão', type: 'Configurations', function: maskMoneyToReal },
};
