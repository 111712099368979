/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateNotificationRequestDto
 */
export interface UpdateNotificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationRequestDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationRequestDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationRequestDto
     */
    actionLink?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationRequestDto
     */
    popupEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationRequestDto
     */
    bellEnabled?: boolean;
}

export function UpdateNotificationRequestDtoFromJSON(json: any): UpdateNotificationRequestDto {
    return UpdateNotificationRequestDtoFromJSONTyped(json, false);
}

export function UpdateNotificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNotificationRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'actionLink': !exists(json, 'actionLink') ? undefined : json['actionLink'],
        'popupEnabled': !exists(json, 'popupEnabled') ? undefined : json['popupEnabled'],
        'bellEnabled': !exists(json, 'bellEnabled') ? undefined : json['bellEnabled'],
    };
}

export function UpdateNotificationRequestDtoToJSON(value?: UpdateNotificationRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'actionLink': value.actionLink,
        'popupEnabled': value.popupEnabled,
        'bellEnabled': value.bellEnabled,
    };
}


