import { SearchIcon } from '@chakra-ui/icons';
import {
	Box,
	BreadcrumbItem,
	BreadcrumbLink,
	Center,
	Flex,
	Heading,
	HStack,
	InputGroup,
	InputRightElement,
	Spinner,
	useBreakpointValue
} from '@chakra-ui/react';
import Breadcrumb from 'components/Breadcrumb';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { GetAllUserNotificationsRequest, NotificationReadStatusApi } from '../../clients';
import Input from '../../components/Input';
import Text from '../../components/Text';
import { getApiAuthConfig } from '../../services/api.service';
import NotificationPagination from './components/NotificationPagination';
import NotificationTabs from './components/NotificationTabs';
import notificationEvent from './events/notificationEvent';
import { GetNotificationReadStatus } from './interfaces/notificationReadStatus';

const NotificationsPanel: React.FC = () => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const notificationReadStatusApi = new NotificationReadStatusApi(apiConfig);
	const textRef = useRef<any>(null);
	const location = useLocation();
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const { notificationId } = location.state || {};

	const [getNotificationsLoading, setGetNotificationsLoading] = React.useState<boolean>(false);
	const [pagination, setPagination] = React.useState<{ currentPage: number; limit: number }>({ currentPage: 1, limit: 10 });
	const [totalPages, setTotalPages] = React.useState<number>(1);
	const [notifications, setNotifications] = React.useState<GetNotificationReadStatus[]>([]);
	const [selectedNotification, setSelectedNotification] = React.useState<GetNotificationReadStatus | null>(null);
	const [searchTerm, setSearchTerm] = React.useState<string>('');
	const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);

	const filteredNotifications: GetNotificationReadStatus[] = notifications.filter((notification) =>
		notification.title.toLowerCase().includes(searchTerm.toLowerCase())
	);
	const unreadNotifications: GetNotificationReadStatus[] = notifications.filter((notification) => !notification.is_read);

	async function getNotifications() {
		setGetNotificationsLoading(true);
		try {
			const getAllNotificationsRequest: GetAllUserNotificationsRequest = {
				currentPage: pagination.currentPage,
				limit: pagination.limit,
			};
			const response = await notificationReadStatusApi.getAllUserNotifications(getAllNotificationsRequest);
			const results: GetNotificationReadStatus[] = (response.results as unknown) as GetNotificationReadStatus[];
			setTotalPages(response.total_pages);
			setNotifications((response.results as unknown) as GetNotificationReadStatus[]);
			if (results[0]) {
				const selectedNotification: GetNotificationReadStatus = results[0];
				setSelectedNotification(selectedNotification);
				updateReadStatus(selectedNotification.id);
			}

			if (notificationId) {
				const notification: GetNotificationReadStatus | undefined = results.find(
					(notification: GetNotificationReadStatus) => notification.id === notificationId
				);
				if (notification) {
					updateReadStatus(notification.id);
					setSelectedNotification(notification);
				}
			}
		} catch (error) {
			addToast('Erro ao buscar as notificações.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setGetNotificationsLoading(false);
		}
	}

	async function updateReadStatus(id: string) {
		try {
			await notificationReadStatusApi.updateReadStatus({ id });
			setNotifications((prevNotifications: GetNotificationReadStatus[]) =>
				prevNotifications.map((notification: GetNotificationReadStatus) =>
					notification.id === id ? { ...notification, is_read: true } : notification
				)
			);
			notificationEvent.emit('notificationRead', id);
		} catch (error) {
			addToast('Erro ao alterar a notificação ativa.', {
				appearance: 'error',
				autoDismiss: true,
			});
		}
	}

	const handleNotificationClick = (notification: GetNotificationReadStatus) => {
		setSelectedNotification(notification);
		updateReadStatus(notification.id);
		if (textRef.current && isMobile) {
			textRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	React.useEffect(() => {
		getNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	useEffect(() => {
		if (notifications && notificationId) {
			const notification: GetNotificationReadStatus | undefined = notifications.find(
				(notification: GetNotificationReadStatus) => notification.id === notificationId
			);
			if (notification) {
				updateReadStatus(notification.id);
				setSelectedNotification(notification);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationId]);

	React.useEffect(() => {
		if (unreadNotifications.length === 0 && selectedTabIndex === 1) {
			setSelectedTabIndex(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unreadNotifications.length]);

	return (
		<Flex flexDirection='column'>
			<HStack justify='space-between'>
				<Breadcrumb>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Resumo</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md'>Notificações</BreadcrumbLink>
					</BreadcrumbItem>
					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink fontSize='md' fontWeight='semibold'>
							Visualizar Notificações
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</HStack>

			<Flex w='100%' borderRadius='md' boxShadow='md' bgColor='white' flexDirection={['column', 'row']}>
				<Box w={['100%', '30%']} overflowX='auto' bgColor='#FAFAFA' borderLeftRadius='md'>
					<Flex p={5} flexDirection='column'>
						<Heading as='h2' size='lg' mb={5}>
							Notificações
						</Heading>

						<InputGroup>
							<Input
								id='notification-search-input'
								placeholder='Pesquise uma palavra-chave'
								borderRadius='md'
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
							/>
							<InputRightElement>
								<SearchIcon color='gray' />
							</InputRightElement>
						</InputGroup>
					</Flex>

					{getNotificationsLoading ? (
						<Center minH='300px'>
							<Spinner />
						</Center>
					) : (
						<>
							<NotificationTabs
								filteredNotifications={filteredNotifications}
								handleNotificationClick={handleNotificationClick}
								selectedNotification={selectedNotification}
								selectedTabIndex={selectedTabIndex}
								setSelectedTabIndex={setSelectedTabIndex}
								unreadNotifications={unreadNotifications}
							/>
							{ totalPages ? 
								<NotificationPagination 
									pagination={pagination} 
									setPagination={setPagination} 
									totalPages={totalPages} 
								/> 
							: null }
						</>
					)}
				</Box>

				<Box w={['100%', '70%']} ref={textRef}>
					<>
						<Heading textAlign='justify' as='h2' size='lg' p={6}>
							{selectedNotification?.title}
						</Heading>
						<Text id='notification-panel-selected-notification-description' textAlign='justify' fontSize='md' p={6}>
							{selectedNotification?.description}
						</Text>
					</>
				</Box>
			</Flex>
		</Flex>
	);
};

export default NotificationsPanel;
