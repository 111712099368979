import { BellIcon } from '@chakra-ui/icons';
import {
	Badge,
	Box,
	Divider,
	Flex,
	IconButton,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spinner,
	Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { GetAllUserNotificationsRequest, NotificationReadStatusApi } from '../../clients';
import notificationEvent from '../../pages/NotificationsPanel/events/notificationEvent';
import { GetNotificationReadStatus } from '../../pages/NotificationsPanel/interfaces/notificationReadStatus';
import { getApiAuthConfig } from '../../services/api.service';
import Button from '../Button';

const NotificationBell: React.FC = () => {
	const { addToast } = useToasts();
	const history = useHistory();
	const apiConfig = getApiAuthConfig();
	const notificationApi = new NotificationReadStatusApi(apiConfig);

	const [notifications, setNotifications] = useState<GetNotificationReadStatus[]>([]);
	const [unreadCount, setUnreadCount] = useState<number>(0);
	const [readAllLoading, setReadAllLoading] = useState<boolean>(false);

	const readAllNotifications = async (): Promise<void> => {
		setReadAllLoading(true);
		try {
			await notificationApi.updateAllReadStatus();
			setNotifications([]);
			setUnreadCount(0);
			addToast('Notificações lidas com sucesso.', {
				appearance: 'success',
				autoDismiss: true,
			});
		} catch (error) {
			addToast('Erro ao marcar as notificações como lidas.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setReadAllLoading(false);
		}
	};

	const handleNotificationClick = (id?: string): void => {
		if (id) {
			history.push({
				pathname: '/admin/notificacoes',
				state: { notificationId: id },
			});

			setNotifications((prevState) => prevState.filter((notification) => notification.id !== id));
			setUnreadCount((prevState) => prevState - 1);
		} else {
			history.push('/admin/notificacoes');
		}
	};

	useEffect(() => {
		const getNotifications = async (): Promise<void> => {
			const request: GetAllUserNotificationsRequest = {
				currentPage: 1,
				limit: 10,
				isRead: false,
			};
			const response = await notificationApi.getAllUserNotifications(request);
			setNotifications((response.results as unknown) as GetNotificationReadStatus[]);
			setUnreadCount(response.results.length);
		};
		getNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleNotificationRead = (notificationId: string): void => {
			setNotifications((prevState) => prevState.filter((notification) => notification.id !== notificationId));
			setUnreadCount((prevState) => prevState - 1);
		};

		notificationEvent.on('notificationRead', handleNotificationRead);

		return () => {
			notificationEvent.off('notificationRead', handleNotificationRead);
		};
	}, []);

	return (
		<Popover placement='bottom-start' closeOnBlur>
			<PopoverTrigger>
				<Flex position='relative' cursor='pointer'>
					<IconButton
						id='notification-bell-popover-button'
						aria-label='Notificações'
						icon={<BellIcon boxSize={6} />}
						variant='ghost'
						color='#22365F'
						_hover={{ bg: 'transparent' }}
						_active={{ bg: 'transparent' }}
						_focus={{ outline: 'none' }}
					/>
					{unreadCount > 0 && (
						<Badge colorScheme='red' position='absolute' top='-1' right='-1' borderRadius='full' px={2}>
							{unreadCount}
						</Badge>
					)}
				</Flex>
			</PopoverTrigger>
			<PopoverContent maxW='280px' boxShadow='lg' borderRadius='md' bg='white' _focus={{ outline: 'none' }}>
				<PopoverArrow />
				<PopoverHeader fontWeight='bold' borderBottom='1px solid' borderColor='gray.200'>
					<Flex justifyContent='space-between' alignItems='center'>
						<Text>Notificações</Text>
						{notifications.length ? (
							<Button
								id='notification-bell-read-all-button'
								variant='unstyled'
								color='primary'
								bgColor='white'
								fontSize='10px'
								isDisabled={readAllLoading}
								size='xs'
								onClick={readAllNotifications}
								_focus={{ outline: 'none' }}
							>
								Marcar todas como lidas
							</Button>
						) : null}
					</Flex>
				</PopoverHeader>

				<PopoverBody p={0}>
					{readAllLoading ? (
						<Flex w='100%' h='100px' justifyContent='center' alignItems='center'>
							<Spinner />
						</Flex>
					) : (
						<Flex direction='column'>
							{notifications.length > 0 ? (
								<>
									{notifications.map((notification, index) => (
										<Box key={notification.id} cursor='pointer'>
											<Flex
												p={3}
												bg={!notification.is_read ? 'gray.100' : 'white'}
												_hover={{ bg: 'gray.50' }}
												_focus={{ outline: 'none' }}
												onClick={() => {
													handleNotificationClick(notification.id);
												}}
											>
												<Flex direction='column' width='100%'>
													<Text fontSize='sm' fontWeight='bold' isTruncated>
														{notification.title}
													</Text>
													<Text fontSize='xs' color='gray.600' isTruncated>
														{notification.description}
													</Text>
												</Flex>
											</Flex>
											{index < notifications.length - 1 && <Divider />}
										</Box>
									))}
								</>
							) : (
								<Flex p={4} justifyContent='center'>
									<Text fontSize='sm'>Nenhuma notificação não lida.</Text>
								</Flex>
							)}

							<Flex justifyContent='center' p={3}>
								<Button
									id='notification-bell-view-all-button'
									variant='unstyled'
									color='primary'
									bgColor='white'
									fontSize='12px'
									size='xs'
									_focus={{ outline: 'none' }}
									onClick={() => handleNotificationClick()}
								>
									Ver todas as notificações
								</Button>
							</Flex>
						</Flex>
					)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default NotificationBell;
