/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetNotificationDto,
    GetNotificationDtoFromJSON,
    GetNotificationDtoToJSON,
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
    UpdateActiveNotificationRequestDto,
    UpdateActiveNotificationRequestDtoFromJSON,
    UpdateActiveNotificationRequestDtoToJSON,
    UpdateNotificationRequestDto,
    UpdateNotificationRequestDtoFromJSON,
    UpdateNotificationRequestDtoToJSON,
} from '../models';

export interface DisableNotificationRequest {
    id: string;
}

export interface GetAllNotificationsRequest {
    limit: number;
    currentPage: number;
}

export interface UpdateActiveNotificationRequest {
    id: string;
    updateActiveNotificationRequestDto: UpdateActiveNotificationRequestDto;
}

export interface UpdateNotificationRequest {
    id: string;
    updateNotificationRequestDto: UpdateNotificationRequestDto;
}

export interface UploadNotificationRequest {
    file: Blob;
    popupEnabled: boolean;
    bellEnabled: boolean;
    name?: string;
    contentType?: string;
    actionLink?: string;
    title?: string;
    description?: string;
}

/**
 * 
 */
export class NotificationApi extends runtime.BaseAPI {

    /**
     * Desabilita uma notificação.
     */
    async disableNotificationRaw(requestParameters: DisableNotificationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disableNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification/disable/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Desabilita uma notificação.
     */
    async disableNotification(requestParameters: DisableNotificationRequest): Promise<void> {
        await this.disableNotificationRaw(requestParameters);
    }

    /**
     * Retorna a notificação ativa
     */
    async getActiveNotificationRaw(): Promise<runtime.ApiResponse<GetNotificationDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNotificationDtoFromJSON(jsonValue));
    }

    /**
     * Retorna a notificação ativa
     */
    async getActiveNotification(): Promise<GetNotificationDto> {
        const response = await this.getActiveNotificationRaw();
        return await response.value();
    }

    /**
     * Retorna todas notificações.
     */
    async getAllNotificationsRaw(requestParameters: GetAllNotificationsRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllNotifications.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getAllNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification/get-all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Retorna todas notificações.
     */
    async getAllNotifications(requestParameters: GetAllNotificationsRequest): Promise<PaginatedDto> {
        const response = await this.getAllNotificationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualiza a notificação que ficará ativa/inativa.
     */
    async updateActiveNotificationRaw(requestParameters: UpdateActiveNotificationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateActiveNotification.');
        }

        if (requestParameters.updateActiveNotificationRequestDto === null || requestParameters.updateActiveNotificationRequestDto === undefined) {
            throw new runtime.RequiredError('updateActiveNotificationRequestDto','Required parameter requestParameters.updateActiveNotificationRequestDto was null or undefined when calling updateActiveNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification/update-active/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateActiveNotificationRequestDtoToJSON(requestParameters.updateActiveNotificationRequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualiza a notificação que ficará ativa/inativa.
     */
    async updateActiveNotification(requestParameters: UpdateActiveNotificationRequest): Promise<void> {
        await this.updateActiveNotificationRaw(requestParameters);
    }

    /**
     * Atualiza os dados da notificação.
     */
    async updateNotificationRaw(requestParameters: UpdateNotificationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNotification.');
        }

        if (requestParameters.updateNotificationRequestDto === null || requestParameters.updateNotificationRequestDto === undefined) {
            throw new runtime.RequiredError('updateNotificationRequestDto','Required parameter requestParameters.updateNotificationRequestDto was null or undefined when calling updateNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNotificationRequestDtoToJSON(requestParameters.updateNotificationRequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualiza os dados da notificação.
     */
    async updateNotification(requestParameters: UpdateNotificationRequest): Promise<void> {
        await this.updateNotificationRaw(requestParameters);
    }

    /**
     * Criar uma nova notificação.
     */
    async uploadNotificationRaw(requestParameters: UploadNotificationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadNotification.');
        }

        if (requestParameters.popupEnabled === null || requestParameters.popupEnabled === undefined) {
            throw new runtime.RequiredError('popupEnabled','Required parameter requestParameters.popupEnabled was null or undefined when calling uploadNotification.');
        }

        if (requestParameters.bellEnabled === null || requestParameters.bellEnabled === undefined) {
            throw new runtime.RequiredError('bellEnabled','Required parameter requestParameters.bellEnabled was null or undefined when calling uploadNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.contentType !== undefined) {
            formParams.append('contentType', requestParameters.contentType as any);
        }

        if (requestParameters.actionLink !== undefined) {
            formParams.append('actionLink', requestParameters.actionLink as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.popupEnabled !== undefined) {
            formParams.append('popupEnabled', requestParameters.popupEnabled as any);
        }

        if (requestParameters.bellEnabled !== undefined) {
            formParams.append('bellEnabled', requestParameters.bellEnabled as any);
        }

        const response = await this.request({
            path: `/v1/notification/upload-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Criar uma nova notificação.
     */
    async uploadNotification(requestParameters: UploadNotificationRequest): Promise<void> {
        await this.uploadNotificationRaw(requestParameters);
    }

}
