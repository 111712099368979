/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetNotificationDto
 */
export interface GetNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    action_link?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationDto
     */
    is_active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationDto
     */
    popup_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationDto
     */
    bell_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationDto
     */
    is_enabled: boolean;
}

export function GetNotificationDtoFromJSON(json: any): GetNotificationDto {
    return GetNotificationDtoFromJSONTyped(json, false);
}

export function GetNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'url': json['url'],
        'action_link': !exists(json, 'action_link') ? undefined : json['action_link'],
        'is_active': json['is_active'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'popup_enabled': json['popup_enabled'],
        'bell_enabled': json['bell_enabled'],
        'is_enabled': json['is_enabled'],
    };
}

export function GetNotificationDtoToJSON(value?: GetNotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'url': value.url,
        'action_link': value.action_link,
        'is_active': value.is_active,
        'title': value.title,
        'description': value.description,
        'popup_enabled': value.popup_enabled,
        'bell_enabled': value.bell_enabled,
        'is_enabled': value.is_enabled,
    };
}


