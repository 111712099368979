import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

export const acquirersRenderer = (value, translatedProp) => {
	const entries: any = Object.entries(value).filter(([key]) => !key.startsWith('_'));

	return (
		<Flex flexDirection='column'>
			<Flex fontWeight='bold' mr='10px'>
				{translatedProp}:
			</Flex>
			{!entries.length ? (
				<Flex>Sem Dados</Flex>
			) : (
				<Flex flexDirection='column'>
					{entries.map(([key, acquirers]) => (
						<Flex key={key} flexDirection='row' alignItems='center'>
							<Flex mr='10px'>Item:</Flex>
							{!acquirers.length ? (
								<Flex>Sem dados</Flex>
							) : acquirers.length === 1 ? (
								<Flex alignItems='center'>
									<Flex>Sem dados</Flex>
									<Flex px='10px'>
										<FaArrowRight />
									</Flex>
									<Flex>{acquirers[0]?.name}</Flex>
								</Flex>
							) : (
								acquirers.map((item, index) => (
									<Flex key={index}>
										<Flex>{item?.name}</Flex>
										{index < acquirers.length - 1 && (
											<Flex px='10px'>
												<FaArrowRight />
											</Flex>
										)}
									</Flex>
								))
							)}
						</Flex>
					))}
				</Flex>
			)}
		</Flex>
	);
};
