import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useBreakpointValue,
	useDisclosure,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import Button from '../../../components/Button';
import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormInput from '../../../components/Form/FormInput';
import FormLabel from '../../../components/Form/FormLabel';
import { SellerEmailKeyEnum } from '../../../clients';

type PreviewModalProps = {
	submitPreview: (email: string) => Promise<void>;
	isPreviewLoading: boolean;
	selectedEmail: string;
};

const PreviewModal: React.FC<PreviewModalProps> = ({ submitPreview, isPreviewLoading, selectedEmail }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const initialValues = {
		email: '',
	};

	const schema = yup.object().shape({
		email: yup.string().required('Campo obrigatório'),
	});

	return (
		<>
			<Button
				display={
					selectedEmail !== SellerEmailKeyEnum.ProtocolsGeneration && selectedEmail !== SellerEmailKeyEnum.BilletEmail ? 'flex' : 'none'
				}
				id='modal-send-preview-open-button'
				size='lg'
				type='button'
				bgColor='primary'
				onClick={onOpen}
				isLoading={isPreviewLoading}
				width={isMobile ? '100%' : ''}
				maxWidth={isMobile ? '300px' : ''}
				mr={isMobile ? 0 : '10px'}
				mb={isMobile ? '10px' : 0}
			>
				Enviar Prévia
			</Button>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>E-mail para Envio</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							enableReinitialize
							initialValues={initialValues}
							validationSchema={schema}
							validateOnMount
							onSubmit={async (values, { resetForm }) => {
								const { email } = values;
								await submitPreview(email);
								resetForm();
								onClose();
							}}
						>
							{({ handleSubmit, isValid }) => (
								<form onSubmit={handleSubmit} style={{ width: '100%' }}>
									<FormControl>
										<FormLabel id='modal-send-preview-email-label' color='darkgrey' fontSize='md' fontWeight='medium'>
											E-mail
										</FormLabel>
										<FormInput id='modal-send-preview-email-input' name='email' fontSize='md' fontWeight='medium' />
										<FormErrorMessage id='modal-send-preview-form-error-text' name='email' />
									</FormControl>

									<ModalFooter px={0}>
										<Button id='modal-send-preview-close-button' bgColor='red' mr={3} onClick={onClose}>
											Fechar
										</Button>
										<Button
											id='modal-send-preview-submit-button'
											type='submit'
											bgColor='primary'
											isDisabled={!isValid}
											isLoading={isPreviewLoading}
										>
											Enviar
										</Button>
									</ModalFooter>
								</form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default PreviewModal;
