import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CellContent, CellContentProps, HeaderTitle, HeaderTitleProps, SimpleTable } from '../../../containers/SimpleTable';
import DetailModal from './DetailModal';
import { DiffGroupTranslations } from './enums/diffGroupTranslations';
import { translations } from './utils/translations';
import { formatToBrazilianTimezone, DateFormatEnum } from '../../../services/masks.service';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold' w='100%'>
			{props.children}
		</CellContent>
	);
};

const SellerChangesHistoryTable = ({ data, setPagination, pagination, totalPages }) => {
	const history = useHistory();

	const getDiffTypes = (diff) => {
		const types: Set<string> = new Set();

		for (const key in translations) {
			if (diff.hasOwnProperty(key)) {
				const field = translations[key];
				if (field.type) {
					types.add(DiffGroupTranslations[field.type as keyof typeof DiffGroupTranslations]);
				}
			}
		}

		return <Flex>{types.size && `${[...types].map((type) => type?.charAt(0).toUpperCase() + type?.slice(1)).join(', ')}.`}</Flex>;
	};

	const columns = React.useMemo(() => {
		return [
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sellers-history-changes-header-diff-group' column={column}>
						Grupo de Alterações
					</HeaderTitleStyled>
				),
				accessor: 'diff-group',
				id: 'diff-group',
				Cell: ({ cell: { row } }) => {
					const diff = row.original.diff;
					return (
						<CellContentStyled id='sellers-history-changes-cell-diff-group' textAlign={`center`}>
							<Box textAlign='left'>{getDiffTypes(diff)}</Box>
						</CellContentStyled>
					);
				},
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sellers-history-changes-header-created-at' column={column}>
						Data e Hora da Alteração
					</HeaderTitleStyled>
				),
				accessor: 'created_at',
				id: 'created_at',
				Cell: ({ cell: { value } }) => (
					<CellContentStyled id='sellers-history-changes-cell-created-at' textAlign={`center`}>
						{formatToBrazilianTimezone(value, DateFormatEnum.FullDate)}
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sellers-history-changes-header-user-name' column={column}>
						Usuário
					</HeaderTitleStyled>
				),
				accessor: 'user_name',
				id: 'user_name',
				Cell: ({ cell: { value } }) => (
					<CellContentStyled id='sellers-history-changes-cell-user-name' textAlign={`center`}>
						{value}
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='sellers-history-changes-header-diff' column={column}>
						Quantidade de Alterações
					</HeaderTitleStyled>
				),
				accessor: 'diff',
				id: 'diff',
				Cell: ({ cell: { value } }) => {
					if (value) {
						return (
							<CellContentStyled id='sellers-history-changes-cell-diff' textAlign={`center`}>
								{Object.keys(value).length ?? 'Sem alterações'}
							</CellContentStyled>
						);
					}
					return (
						<CellContentStyled id='sellers-history-changes-cell-diff-unchanged' textAlign={`center`}>
							Sem alterações
						</CellContentStyled>
					);
				},
			},
			{
				Header: ({ column }) => <HeaderTitleStyled id='sellers-history-changes-header-details' column={column}></HeaderTitleStyled>,
				accessor: 'details',
				Cell: ({ cell: { row } }) => {
					const originalData = row.original;

					return (
						<Flex>
							<DetailModal item={originalData} />
						</Flex>
					);
				},
			},
		];
		// eslint-disable-next-line
	}, [history]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};

export default SellerChangesHistoryTable;
