import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

export const merchantSalePlanRenderer = (value, translatedProp) => {
	const renderObjectValues = (translatedProp: string, objectValues: Array<{ id: string; name?: string }>) => {
		return (
			<Flex>
				<Flex flexDirection='row' alignItems='center' display='inline-flex'>
					<Flex fontWeight='bold' mr='10px'>
						{translatedProp}:
					</Flex>

					{objectValues.length === 1 ? (
						<Flex flexDirection='row' alignItems='center' display='inline-flex'>
							Sem dados{' '}
							<Flex px='10px'>
								<FaArrowRight />
							</Flex>{' '}
							{objectValues[0].name ?? 'Sem Dados'}
						</Flex>
					) : (
						objectValues?.map((item, index) => (
							<Flex key={item.id} flexDirection='row' alignItems='center'>
								{item?.name ?? 'Sem Dados'}
								{index < objectValues.length - 1 && (
									<Flex px='10px'>
										<FaArrowRight />
									</Flex>
								)}
							</Flex>
						))
					)}
				</Flex>
			</Flex>
		);
	};

	if (typeof value === 'object' && !Array.isArray(value)) {
		const filteredValue: any = Object.entries(value)
			.filter(([key]) => !key.startsWith('_'))
			.flatMap(([, value]) => value)
			.filter((item: any) => item && item.id);

		return renderObjectValues(translatedProp, filteredValue);
	}
	if (Array.isArray(value)) {
		return renderObjectValues(translatedProp, value);
	} else {
		return <Flex>Sem dados alterados</Flex>;
	}
};
