/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SellerEmail,
    SellerEmailFromJSON,
    SellerEmailFromJSONTyped,
    SellerEmailToJSON,
} from './';

/**
 * 
 * @export
 * @interface SendPreviewEmailDto
 */
export interface SendPreviewEmailDto {
    /**
     * 
     * @type {string}
     * @memberof SendPreviewEmailDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SendPreviewEmailDto
     */
    seller_name: string;
    /**
     * 
     * @type {SellerEmail}
     * @memberof SendPreviewEmailDto
     */
    custom_preview: SellerEmail;
    /**
     * 
     * @type {string}
     * @memberof SendPreviewEmailDto
     */
    logo?: string;
}

export function SendPreviewEmailDtoFromJSON(json: any): SendPreviewEmailDto {
    return SendPreviewEmailDtoFromJSONTyped(json, false);
}

export function SendPreviewEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendPreviewEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'seller_name': json['seller_name'],
        'custom_preview': SellerEmailFromJSON(json['custom_preview']),
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
    };
}

export function SendPreviewEmailDtoToJSON(value?: SendPreviewEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'seller_name': value.seller_name,
        'custom_preview': SellerEmailToJSON(value.custom_preview),
        'logo': value.logo,
    };
}


