import { Flex, HStack, Textarea, useBreakpointValue, VStack, Input } from '@chakra-ui/react';
import React from 'react';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import FormControl from '../../../components/Form/FormControl';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';
import FormLabel from '../../../components/Form/FormLabel';
import Text from '../../../components/Text';
import FileUploadInput from '../../../containers/FileUploadInput';
import FormInput from '../../../components/Form/FormInput';

type NotificationFormProps = {
	file: File | null;
	setFile: React.Dispatch<React.SetStateAction<File | null>>;
	isLoading: boolean;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	values: {
		action_link: string;
		popup_enabled: boolean;
		bell_enabled: boolean;
		title: string;
		description: string;
	};
};

export const NotificationForm = (props: NotificationFormProps) => {
	const isMobile = useBreakpointValue({ base: true, lg: false });

	return (
		<VStack spacing={4} mb={4} alignItems='flex-start' width='100%'>
			<HStack spacing={isMobile ? 0 : 4} wrap='wrap' width='100%' direction={isMobile ? 'column' : 'row'}>
				<Flex>
					<FormControl id='notification-popup_enabled-checkbox'>
						<Checkbox
							id='notification-popup_enabled-checkbox'
							name='popup_enabled'
							color='darkGrey'
							fontSize='md'
							fontWeight='medium'
							onChange={(e) => props.setFieldValue('popup_enabled', e.target.checked)}
							colorScheme='primary_checkbox'
							isChecked={props.values.popup_enabled}
						>
							Adicionar como Popup
						</Checkbox>
					</FormControl>
				</Flex>

				<Flex>
					<FormControl id='notification-bell_enabled-checkbox'>
						<Checkbox
							id='notification-bell_enabled-checkbox'
							name='bell_enabled'
							color='darkGrey'
							fontSize='md'
							fontWeight='medium'
							onChange={(e) => props.setFieldValue('bell_enabled', e.target.checked)}
							colorScheme='primary_checkbox'
							isChecked={props.values.bell_enabled}
						>
							Adicionar como Notificação
						</Checkbox>
					</FormControl>
				</Flex>
			</HStack>

			{props.values.popup_enabled && (
				<Flex width='100%' direction={isMobile ? 'column' : 'row'} wrap='wrap' alignItems='flex-start'>
					<Flex width={isMobile ? '100%' : '36%'}>
						<FormControl id='notification-action_link' marginRight={isMobile ? '0' : '10px'}>
							<FormLabel id='notification-title-form-label' fontSize='md' fontWeight='bold'>
								Enviar Arquivo
							</FormLabel>
							<Flex
								alignItems='center'
								border='1px solid lightgray'
								borderRadius='md'
								justifyContent='center'
								p={2}
								flexDirection={isMobile ? 'column' : 'row'}
							>
								<FileUploadInput
									id='notification-form-file-upload'
									containerProps={{ m: 0, width: '100%' }}
									inputProps={{
										onChange: (e) => {
											if (e.target.files?.length) {
												props.setFile(e.target.files[0]);
											}
										},
										accept: 'image/png, image/jpg, image/jpeg',
									}}
								/>
								<Text id='notification-form-image-text' mx={2} whiteSpace='nowrap' textAlign='center' width='100%'>
									{props.file ? props.file.name : 'Selecione uma imagem.'}
								</Text>
							</Flex>
						</FormControl>
					</Flex>
					<Flex width={isMobile ? '100%' : '60%'}>
						<FormControl id='notification-action_link'>
							<FormLabel id='notification-form-action-link-label' fontSize='md' fontWeight='bold'>
								Link de Ação
							</FormLabel>
							<FormInput id='notification-action_link' as={Input} name='action_link' />
							<FormErrorMessage id='notification-form-action-link-error' name='action_link' />
						</FormControl>
					</Flex>
				</Flex>
			)}

			{props.values.bell_enabled && (
				<Flex width='100%' direction={isMobile ? 'column' : 'row'} wrap='wrap' alignItems='flex-start'>
					<Flex width={isMobile ? '100%' : '35%'} marginRight={isMobile ? '0' : '10px'} mb={isMobile ? '10px' : '0'}>
						<FormControl id='notification-title'>
							<FormLabel id='notification-form-title-label' fontSize='md' fontWeight='bold'>
								Título
							</FormLabel>
							<FormInput id='notification-title' as={Input} name='title' />
							<FormErrorMessage id='notification-form-title-error' name='title' />
						</FormControl>
					</Flex>

					<Flex width={isMobile ? '100%' : '60%'}>
						<FormControl id='notification-description'>
							<FormLabel id='notification-form-description-label' fontSize='md' fontWeight='bold'>
								Descrição
							</FormLabel>
							<FormInput id='notification-description' as={Textarea} name='description' />
							<FormErrorMessage id='notification-form-description-error' name='description' />
						</FormControl>
					</Flex>
				</Flex>
			)}

			<Flex>
				<Button
					id='notification-submit-button'
					bgColor='primary'
					color='white'
					isDisabled={
						(!props.values.popup_enabled && !props.values.bell_enabled) ||
						(props.values.popup_enabled && !props.file) ||
						(props.values.bell_enabled && (!props.values.title || !props.values.description))
					}
					isLoading={props.isLoading}
					type='submit'
				>
					Enviar
				</Button>
			</Flex>
		</VStack>
	);
};
