import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { DisableNotificationRequest, GetNotificationDto, NotificationApi } from '../../../clients';
import Button from '../../../components/Button';
import { getApiAuthConfig } from '../../../services/api.service';

interface DeleteModalProps {
	isOpen: boolean;
	onClose: () => void;
	row: GetNotificationDto;
	getAllNotifications(): Promise<void>;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, row, getAllNotifications }) => {
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const notificationApi = new NotificationApi(apiConfig);

	const [changesIsLoading, setChangesIsLoading] = React.useState<boolean>(false);

	const handleDelete = async (): Promise<void> => {
		setChangesIsLoading(true);
		try {
			const disableNotificationRequest: DisableNotificationRequest = {
				id: row.id,
			};
			await notificationApi.disableNotification(disableNotificationRequest);
			addToast('Notificação excluída com sucesso.', {
				appearance: 'success',
				autoDismiss: true,
			});
			getAllNotifications();
		} catch (error) {
			addToast('Erro ao tentar excluir a notificação.', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setChangesIsLoading(false);
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='md'>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Excluir Notificação</ModalHeader>
				<ModalCloseButton />
				<ModalBody>Tem certeza que deseja excluir esta notificação?</ModalBody>
				<ModalFooter>
					<Button id='notification-delete-modal-return-button' bgColor='gray' onClick={onClose} mr='10px'>
						Não
					</Button>
					<Button
						id='notification-delete-modal-submit-button'
						isLoading={changesIsLoading}
						isDisabled={changesIsLoading}
						bgColor='primary'
						color='white'
						type='submit'
						onClick={handleDelete}
					>
						Sim
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeleteModal;
