import * as React from 'react';

import { InputProps } from '@chakra-ui/react';

import InputMask from "react-input-mask";
import Input from './Input';

export type NumberInputProps = {
	length?: number;
	id: string;
};

const NumberInput: React.FC<NumberInputProps & InputProps> = (props: NumberInputProps & InputProps) => {
	return (
		<InputMask
			mask={props.length ? `${'9'.repeat(props.length)}` : '99999'}
			formatChars={{ 9: "[0-9]", "?": "[0-9 ]" }}
			maskChar={null}
			{...props}		
		>
			{(inputProps) => {
				return (
					<Input id={props.id} type="text" {...inputProps} />
				);
			}}
		</InputMask>
	)
};

export default NumberInput;
