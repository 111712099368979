import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

export const responsiblesRenderer = (value, translatedProp) => {
	const oldValues = Object.entries(value).filter(([key]) => key.startsWith('_') && key !== '_t');
	const newValues = Object.entries(value).filter(([key]) => !key.startsWith('_') && key !== '_t');

	const translateType = (type) => {
		const translationMap = {
			support: 'Suporte',
			commercial: 'Comercial',
		};
		return translationMap[type.toLowerCase()] || type;
	};

	return (
		<Flex flexDirection='column'>
			<Flex fontWeight='bold' mr='10px'>
				{translatedProp}:
			</Flex>
			{!oldValues.length ? (
				<Flex flexDirection='row' alignItems='center'>
					<Flex>Sem dados</Flex>
					<Flex px='10px'>
						<FaArrowRight />
					</Flex>
					<Flex>
						{newValues.map(([_, newValue]) =>
							Array.isArray(newValue) && newValue.length ? (
								<Flex key={newValue[0]?.type}>
									{newValue.map((item) => item.name)} ({translateType(newValue[0]?.type)})
								</Flex>
							) : (
								'Sem dados'
							)
						)}
					</Flex>
				</Flex>
			) : (
				oldValues?.map(([key, oldValue]) => {
					const newValueEntry = newValues?.find(([newKey]) => newKey === key.replace('_', ''));
					const newValue = newValueEntry ? newValueEntry[1] : null;

					return (
						<Flex key={key} flexDirection='row' alignItems='center'>
							<Flex>
								{Array.isArray(oldValue) && oldValue.length ? (
									<Flex>
										{oldValue.map((item) => item.name)} ({translateType(oldValue[0]?.type)})
									</Flex>
								) : (
									'Sem dados'
								)}
							</Flex>
							<Flex px='10px'>
								<FaArrowRight />
							</Flex>
							<Flex>
								{Array.isArray(newValue) && newValue.length ? (
									<Flex>
										{newValue.map((item) => item.name)} ({translateType(newValue[0]?.type)})
									</Flex>
								) : (
									'Sem dados'
								)}
							</Flex>
						</Flex>
					);
				})
			)}
		</Flex>
	);
};
