import Icon from '@chakra-ui/icon';
import { Button, Flex, Switch, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { FiEdit, FiXSquare } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { CellContent, CellContentProps, HeaderTitle, HeaderTitleProps, SimpleTable } from '../../../containers/SimpleTable';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages, updateActive, isLoading, getAllNotifications }) => {
	const history = useHistory();
	const columns = React.useMemo(() => {
		let cols: any = [];

		return cols.concat([
			{
				Header: ({ column }) => <HeaderTitleStyled id='notifications-table-header-name' column={column}>Nome do Arquivo</HeaderTitleStyled>,
				accessor: 'name',
				Cell: ({ cell: { value } }) => <CellContentStyled id='notifications-table-cell-name'>{value ? value: 'Não se aplica'}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled id='notifications-table-header-title' column={column}>Título da Notificação</HeaderTitleStyled>,
				accessor: 'title',
				Cell: ({ cell: { value } }) => <CellContentStyled id='notifications-table-cell-title'>{value ? value: 'Não se aplica'}</CellContentStyled>,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled id='notifications-table-header-is-active' column={column}>Popup Ativo</HeaderTitleStyled>,
				accessor: 'is_active',
				Cell: ({ cell: { row, value } }) =>
					row.original.popup_enabled ? (
						<Switch
							defaultChecked={value}
							isChecked={value}
							onChange={(e) => {
								updateActive(row.original.id, e.target.checked);
							}}
						/>
					) : null,
			},
			{
				Header: ({ column }) => <HeaderTitleStyled id='url-title' column={column}></HeaderTitleStyled>,
				accessor: 'url',
				id: 'edit',
				Cell: ({ cell: { row, value } }) => {
					const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
					const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

					return (
						<Flex flexDirection='row'>
							<Flex justifyContent='flex-end' mr='15px'>
								<Button id='notification-edit-button' onClick={onEditOpen} background={'transparent'} p={1} minW={0}>
									<Icon as={FiEdit} boxSize={4} />
								</Button>
								<EditModal
									isOpen={isEditOpen}
									onClose={onEditClose}
									imageUrl={value}
									row={row.original}
									getAllNotifications={getAllNotifications}
								/>
							</Flex>
							<Flex justifyContent='flex-end'>
								<Button id='notification-delete-button' onClick={onDeleteOpen} background={'transparent'} p={1} minW={0}>
									<Icon as={FiXSquare} boxSize={4} />
								</Button>
								<DeleteModal isOpen={isDeleteOpen} onClose={onDeleteClose} row={row.original} getAllNotifications={getAllNotifications} />
							</Flex>
						</Flex>
					);
				},
			},
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			pointerEvents={isLoading ? 'none' : 'auto'}
			opacity={isLoading ? 0.5 : 1}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};
