import {
	Box,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { BiDetail } from 'react-icons/bi';
import { absorbCostsAndNoPaymentRenderer } from './utils/renderers/absorbCostsAndNoPaymentRenderer';
import { acquirersRenderer } from './utils/renderers/acquirersRenderer';
import { defaultRenderer } from './utils/renderers/defaultRenderer';
import { merchantSalePlanRenderer } from './utils/renderers/merchantSalePlanRenderer';
import { responsiblesRenderer } from './utils/renderers/responsiblesRenderer';
import { translations } from './utils/translations';

interface DetailModalProps {
	item: {
		diff: { [key: string]: any };
	};
}

const DetailModal: React.FC<DetailModalProps> = ({ item }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { diff } = item;

	const checkIfResultIsArray = (value) => {
		return Array.isArray(value);
	};

	const renderChanges = (value, prop) => {
		const translatedProp = translations[prop]?.value || prop;
		const maskFunction = translations[prop]?.function;

		if (typeof value !== 'object' || value === null) {
			return (
				<Flex>
					{translatedProp}: {value}
				</Flex>
			);
		}

		switch (prop) {
			case 'merchant_id':
			case 'sale_plan_id':
				return merchantSalePlanRenderer(value, translatedProp);

			case 'responsibles':
				return responsiblesRenderer(value, translatedProp);

			case 'absorb_costs':
			case 'no_payment_methods':
			case 'attachments':
				return absorbCostsAndNoPaymentRenderer(value, translatedProp, maskFunction);

			case 'acquirers':
				return acquirersRenderer(value, translatedProp);

			default:
				return defaultRenderer(value, translatedProp, maskFunction, renderChanges, checkIfResultIsArray);
		}
	};

	return (
		<>
			<Stack w='100%' _hover={{ opacity: 0.7, cursor: 'pointer' }} onClick={onOpen}>
				<Icon as={BiDetail} boxSize={5} />
			</Stack>

			<Modal isOpen={isOpen} onClose={onClose} size='4xl'>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Histórico de Alterações</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{diff && typeof diff === 'object' && Object.keys(diff).length > 0 ? (
							Object.keys(diff).map((prop) => (
								<Box key={prop} mb={4}>
									{renderChanges(diff[prop], prop)}
								</Box>
							))
						) : (
							<Flex>Nenhuma alteração disponível</Flex>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default DetailModal;
