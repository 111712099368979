/* tslint:disable */
/* eslint-disable */
/**
 * Parcela Express API
 * Parcela Express API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PaginatedDto,
    PaginatedDtoFromJSON,
    PaginatedDtoToJSON,
} from '../models';

export interface GetAllUserNotificationsRequest {
    limit: number;
    currentPage: number;
    isRead?: boolean;
}

export interface UpdateReadStatusRequest {
    id: string;
}

/**
 * 
 */
export class NotificationReadStatusApi extends runtime.BaseAPI {

    /**
     * Retorna todas notificações do usuário.
     */
    async getAllUserNotificationsRaw(requestParameters: GetAllUserNotificationsRequest): Promise<runtime.ApiResponse<PaginatedDto>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllUserNotifications.');
        }

        if (requestParameters.currentPage === null || requestParameters.currentPage === undefined) {
            throw new runtime.RequiredError('currentPage','Required parameter requestParameters.currentPage was null or undefined when calling getAllUserNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.currentPage !== undefined) {
            queryParameters['current_page'] = requestParameters.currentPage;
        }

        if (requestParameters.isRead !== undefined) {
            queryParameters['is_read'] = requestParameters.isRead;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification-read-status/get-all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedDtoFromJSON(jsonValue));
    }

    /**
     * Retorna todas notificações do usuário.
     */
    async getAllUserNotifications(requestParameters: GetAllUserNotificationsRequest): Promise<PaginatedDto> {
        const response = await this.getAllUserNotificationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Atualiza o status de todas as notificações paras lidas.
     */
    async updateAllReadStatusRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification-read-status/read-all`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualiza o status de todas as notificações paras lidas.
     */
    async updateAllReadStatus(): Promise<void> {
        await this.updateAllReadStatusRaw();
    }

    /**
     * Atualiza o status da notificação para lida.
     */
    async updateReadStatusRaw(requestParameters: UpdateReadStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateReadStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notification-read-status/read/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Atualiza o status da notificação para lida.
     */
    async updateReadStatus(requestParameters: UpdateReadStatusRequest): Promise<void> {
        await this.updateReadStatusRaw(requestParameters);
    }

}
