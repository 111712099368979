import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Flex,
	IconButton,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInputField,
	NumberInputStepper,
	Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import NumberInput from '../../../components/NumberInput';
import Select from '../../../components/Select';
import Text from '../../../components/Text';

interface NotificationPaginationProps {
	pagination: {
		currentPage: number;
		limit: number;
	};
	setPagination: React.Dispatch<
		React.SetStateAction<{
			currentPage: number;
			limit: number;
		}>
	>;
	totalPages: number;
}

const NotificationPagination: React.FC<NotificationPaginationProps> = ({ pagination, setPagination, totalPages }) => {
	const setPage = (value: number) => {
		let page = 1;

		if (value < 1) {
			page = 1;
		} else if (value > totalPages) {
			page = totalPages;
		} else {
			page = value;
		}

		setPagination({ currentPage: page, limit: pagination.limit });
	};

	return (
		<Flex flexDirection='column' mt='2' alignItems='center' fontSize={['md', 'md', 'inherit']}>
			<Text id='notification-panel-pagination-page-text' flexShrink={0}>
				Pagina{' '}
				<Text id='notification-panel-pagination-current-page-text' fontWeight='bold' as='span'>
					{pagination.currentPage}
				</Text>{' '}
				de{' '}
				<Text id='notification-panel-pagination-total-pages-text' fontWeight='bold' as='span'>
					{totalPages}
				</Text>
			</Text>
			<Flex mt={2}>
				<Tooltip label='Primeira página'>
					<IconButton
						id='notifications-first-page-button'
						aria-label='Primeira página'
						onClick={() => setPagination({ currentPage: 1, limit: pagination.limit })}
						isDisabled={pagination.currentPage === 1}
						icon={<ArrowLeftIcon h={3} w={3} />}
					/>
				</Tooltip>

				<Tooltip label='Página anterior'>
					<IconButton
						id='notifications-previous-page-button'
						aria-label='Página anterior'
						onClick={() =>
							setPagination({
								currentPage: pagination.currentPage - 1,
								limit: pagination.limit,
							})
						}
						isDisabled={pagination.currentPage === 1}
						icon={<ChevronLeftIcon h={6} w={6} />}
					/>
				</Tooltip>

				<Tooltip label='Proxima página'>
					<IconButton
						id='notifications-next-page-button'
						aria-label='Proxima página'
						onClick={() =>
							setPagination({
								currentPage: pagination.currentPage + 1,
								limit: pagination.limit,
							})
						}
						isDisabled={pagination.currentPage === totalPages}
						icon={<ChevronRightIcon h={6} w={6} />}
					/>
				</Tooltip>

				<Tooltip label='Ultima página'>
					<IconButton
						id='notifications-last-page-button'
						aria-label='Ultima página'
						onClick={() => setPagination({ currentPage: totalPages, limit: pagination.limit })}
						isDisabled={pagination.currentPage === totalPages}
						icon={<ArrowRightIcon h={3} w={3} />}
						ml={4}
					/>
				</Tooltip>
			</Flex>

			<Flex direction={['column', 'column', 'row']} alignItems='center' mt={2}>
				<Text id='notification-panel-pagination-go-to-page-text'>Ir para pagina: </Text>{' '}
				<NumberInput
					id='notifications-page-button'
					w={28}
					min={1}
					max={totalPages}
					defaultValue={pagination.currentPage}
					onBlur={(e) => setPage(Number(e.target.value))}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							setPage(Number((e.target as HTMLInputElement).value));
						}
					}}
				>
					<NumberInputField />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
			</Flex>
			<Flex my={4}>
				<Select
					id='notifications-page-limit-button'
					w={32}
					value={pagination.limit}
					onChange={(e) => {
						setPagination({ currentPage: 1, limit: Number(e.target.value) });
					}}
				>
					{[10, 20, 30, 40, 50, 100].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Ver {pageSize}
						</option>
					))}
				</Select>
			</Flex>
		</Flex>
	);
};

export default NotificationPagination;
