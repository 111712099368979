import {
	Button,
	Flex,
	Box,
	Text,
	Stack,
	HStack,
	Image,
	useBreakpointValue,
	MenuButton,
	Menu as ChakraMenu,
	MenuList,
} from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FaSignOutAlt } from 'react-icons/fa';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import { HamburgerIcon } from '@chakra-ui/icons';
import { getApiAuthConfig } from 'services/api.service';

import EmailsCustom from 'pages/EmailsCustom';
import Merchants from 'pages/Merchants';
import CreateMerchant from 'pages/Merchants/CreateMerchant';
import EditMerchant from 'pages/Merchants/EditMerchant';
import ProtocolsCustom from 'pages/ProtocolsCustom';
import { GetSellerDto, UserLoginResponseDtoTypeEnum, SellersApi, PermissionActionsEnum, PermissionSubjectEnum } from '../../clients';
import ProtectedRoute from '../../components/ProtectedRoute';
import { useAuth } from '../../contexts/AuthProvider';
import Dashboard from '../../pages/Dashboard';

import Menu, { MobileMenu } from './Menu';
import PageNotFound from './PageNotFound';

import PosSale from '../PosSale';
import BilletSale from '../BilletSale';
import PixSale from '../PixSale';
import SaleDetails from '../SaleDetails';
import SalesHistory from '../SalesHistory';
import SalesHistoryPayment from '../SalesHistoryPayment';
import PaymentHistory from '../../pages/PaymentHistory';
import MyData from '../../pages/MyData';
import MyPlan from '../../pages/MyPlan';
import Users from '../../pages/Users';
import BackofficePOS from '../BackofficePOS';
import BackofficeUsers from '../../pages/Users/BackofficeUsers';
import PaymentDetails from '../../pages/PaymentDetails';
import PaymentLinkSale from '../../pages/PaymentLinkSale';
import Calendar from '../../pages/Calendar';
import Reports from '../../pages/Reports';
import Sellers from '../../pages/Sellers';
import Roles from '../../pages/Roles';
import SellerSelect from '../../components/SellerSelect';
import Can, { AbilityContext } from '../../contexts/Can';
import { SellerSelectHandle } from '../../components/SellerSelect/SellerSelect';
import { SellerProvider, useCurrentSeller } from '../../contexts/SellerProvider';
import CreateSeller from '../Sellers/CreateSeller';
import CostPlans from '../CostPlans';
import CreateCostPlan from '../CostPlans/CreateCostPlan';
import CostPlanDetails from '../CostPlans/CostPlanDetails';
import SalesPlan from '../SalesPlan';
import SalesPlanDetails from '../SalesPlan/SalesPlanDetails';
import CreateSalePlan from '../SalesPlan/CreateSalePlan';
import ProtestLinkSale from '../ProtestLinkSale/ProtestLinkSale';
import Customization from '../Customization';
import EditSeller from '../Sellers/EditSeller';
import Protocols from '../Protocols';
import EditProtocol from '../Protocols/EditProtocol';
import ProtocolsHistory from '../ProtocolsHistory';
import BillSale from '../BillSale';

import SellerCredentials from 'pages/Sellers/SellerCredentials';
import UploadRetFile from 'pages/UploadRetFile';
import MerchantUsers from 'pages/MerchantUsers';
import ReportCustomization from 'pages/ReportCustomization';
import Responsibles from 'pages/Responsibles';
import CreateResponsible from 'pages/Responsibles/CreateResponsible';
import EditResponsible from 'pages/Responsibles/EditResponsible';
import AssignmentTransactionAlerts from 'pages/AssignmentTransactionAlerts';
import AssignmentTransactionAlertDetails from 'pages/AssignmentTransactionAlerts/AssignmentTransactionAlertDetails';

import Loader from 'containers/Loader';

import * as variables from '../../config/variables';
import PaymentCheckoutTest from '../PaymentCheckoutTest';
import MonthlyTransactionsAlerts from '../MonthlyTransactionsAlerts';

import Goals from 'pages/Goals';
import { getRedirectRoute } from 'pages/Login/Login';
import BillPaymentHistory from 'pages/BillPaymentHistory';
import CheckoutsCustomization from '../CheckoutsCustomization';
import Notifications from '../Notifications';
import CustomPopover from '../../components/CustomPopover';
import PaperRollOrder from '../PaperRollOrder';
import PaperRollOrderHeaderButton from '../PaperRollOrder/components/HeaderButton';
import FindDda from '../FindDda';
import NotificationBell from '../../components/Notification/NotificationBell';
import NotificationsPanel from '../NotificationsPanel';
import SellerHistoryChanges from '../Sellers/historyChanges/SellerHistoryChanges';
import PosPassword from '../PosPassword';

function EnhancedRoute({ component: Component, ...rest }) {
	const { user, logout, isBackoffice, isMerchant } = useAuth();
	const { currentSeller, updateCurrentSeller } = useCurrentSeller();
	const ability = React.useContext(AbilityContext);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const isMobile = useBreakpointValue({ base: true, lg: false });

	const ref = React.useRef<SellerSelectHandle>(null);
	const handleClearCurrentSeller = () => {
		ref.current?.clear();

		updateCurrentSeller(undefined);
	};

	const handleChangeSeller = async (seller: GetSellerDto) => {
		const apiConfig = getApiAuthConfig();
		const sellerApi = new SellersApi(apiConfig);

		setIsLoading(true);

		try {
			const updatedSeller = await sellerApi.getSeller({ sellerId: seller.id });

			updateCurrentSeller(updatedSeller);
		} finally {
			setIsLoading(false);
		}
	};

	const redirectRoute = getRedirectRoute(ability);

	return (
		<Flex direction='column' height='100%' width='100%'>
			{isBackoffice || isMerchant ? (
				<>{currentSeller && <CustomPopover sellerId={currentSeller.id} />}</>
			) : (
				<>{user?.seller && <CustomPopover sellerId={user.seller.id} />}</>
			)}
			{currentSeller && <CustomPopover sellerId={currentSeller.id} />}
			<Loader isOpen={isLoading} />

			<Helmet>
				<title>{`${process.env.REACT_APP_NAME} | ${rest.title}`}</title>
				<meta name='description' content={`${process.env.REACT_APP_NAME}`} />
			</Helmet>

			<Flex as='header' height={['auto', 'auto', 'headerHeight']} flexDirection='column' w='100%'>
				<Flex w='100%' flexDir={isMobile ? 'column' : 'row'} bgColor='white' py={2} px={['2', '2', '5']} top={0}>
					{isMobile && (
						<HStack spacing='4' justifyContent='space-between'>
							<ChakraMenu>
								{({ isOpen }) => (
									<>
										<MenuButton bgColor='transparent' borderColor='primary' color='primary' isActive={isOpen} as={Button}>
											<HamburgerIcon />
										</MenuButton>
										<MenuList zIndex={9999} px='4'>
											<MobileMenu />
										</MenuList>
									</>
								)}
							</ChakraMenu>
							{isBackoffice || isMerchant ? (
								<Text id='current-seller-description-text' fontWeight='normal' color='primary' className='header__seller-name'>
									{currentSeller ? currentSeller.description || currentSeller.name : 'Todos os Estabelecimentos'}
								</Text>
							) : (
								<Text id='user-seller-description-or-name-text' fontWeight='normal' color='primary' className='header__seller-name'>
									{user.seller.description || user.seller.name}
								</Text>
							)}
							{currentSeller && <PaperRollOrderHeaderButton seller={currentSeller} />}
							<NotificationBell/>
							<Button id='logout-button' bgColor='transparent'>
								<Text id='logout-text' fontWeight='normal' fontSize='md' color='primary'>
									<FaSignOutAlt onClick={logout} />
								</Text>
							</Button>
						</HStack>
					)}
					<Flex w='100%' flexDir='row' justifyContent={isMobile ? 'center' : 'space-between'} alignItems='center' px={['0', '0', '16']}>
						<HStack spacing='8'>
							{!isMobile && (
								<>
									<Image src={user?.logo?.url ? user.logo.url : variables.logoUrl} maxW='23.75rem' maxH='5.625rem' />
									{isBackoffice || isMerchant ? (
										<Text id='current-seller-mobile-text' fontWeight='normal' color='primary' className='header__seller-name'>
											{currentSeller ? currentSeller.description || currentSeller.name : 'Todos os Estabelecimentos'}
										</Text>
									) : (
										<Text
											id='user-seller-description-or-name-mobile-text'
											fontWeight='normal'
											color='primary'
											className='header__seller-name'
										>
											{user.seller && (user.seller.description || user.seller.name)}
										</Text>
									)}
								</>
							)}
							<Can do={PermissionActionsEnum.Read} on={PermissionSubjectEnum.ChangeSeller}>
								<Stack direction={['column', 'column', 'row']} spacing={2} alignItems='center'>
									<SellerSelect ref={ref} onChange={handleChangeSeller} />
									<Button id='all-button' bgColor='primary' color='white' onClick={handleClearCurrentSeller} mr='2'>
										Ver Todos
									</Button>
								</Stack>
							</Can>
						</HStack>
						<HStack spacing='8'>
							{!isMobile && (
								<>
									{currentSeller && <PaperRollOrderHeaderButton seller={currentSeller} />}
									<NotificationBell/>
									<Button
										id='logout-mobile-button'
										rounded='md'
										bgColor='primary'
										color='white'
										_hover={{ opacity: 0.7, cursor: 'pointer' }}
										onClick={logout}
										mr='6'
									>
										Logout
									</Button>
								</>
							)}
						</HStack>
					</Flex>
				</Flex>
			</Flex>

			<Flex height='100%' direction='row'>
				<Menu />
				<Box height='100%' width={['100%', '100%', '100%', '75%', '80%']} pl={[4, 8]} pr={[4, 8]} pt={[4, 4, 4, 12]} mb={10}>
					<Can not do={rest.action} on={rest.subject}>
						<Redirect to={{ pathname: redirectRoute }} />
					</Can>
					<ProtectedRoute {...rest} component={Component} />
				</Box>
			</Flex>
		</Flex>
	);
}

const Admin: React.FC = () => {
	const { path } = useRouteMatch();
	const { user } = useAuth();

	if (user && user.type === UserLoginResponseDtoTypeEnum.Seller && !user?.seller) {
		return <></>;
	}

	return (
		<SellerProvider seller={user?.seller}>
			<Box bgColor='default' overflow='auto' minHeight='100%'>
				<Route path='/' />
				<Switch>
					<EnhancedRoute
						path={`${path}/dashboard`}
						title='Resumo de Vendas'
						component={Dashboard}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Dashboard}
						id='Dashboard'
					/>
					<EnhancedRoute
						path={`${path}/venda/pos`}
						title='Venda na Máquina'
						component={PosSale}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Pos}
						id='POS'
					/>
					<EnhancedRoute
						path={`${path}/venda/boleto`}
						title='Boleto'
						component={BilletSale}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Billet}
						id='Billet'
					/>
					<EnhancedRoute
						path={`${path}/venda/pix`}
						title='Pix'
						component={PixSale}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Pix}
						id='Pix'
					/>
					<EnhancedRoute
						path={`${path}/venda/pagamento_de_conta`}
						title='Pagamento de conta'
						component={BillSale}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.BillPayment}
						id='Pagamento_de_conta'
					/>
					<EnhancedRoute
						path={`${path}/venda/link`}
						title='Link de Pagamento'
						component={PaymentLinkSale}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Link}
						id='Link'
					/>
					<EnhancedRoute
						path={`${path}/calendario`}
						title='Calendário'
						component={Calendar}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Calendar}
						id='Calendar'
					/>
					<EnhancedRoute
						path={`${path}/relatorios`}
						title='Relatórios Exportados'
						component={Reports}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Reports}
						id='Reports'
					/>
					<EnhancedRoute
						exact
						path={`${path}/sales-history`}
						title='Histórico de Vendas'
						component={SalesHistory}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.SalesHistory}
						id='SalesHistory'
					/>
					<EnhancedRoute
						path={`${path}/sales-history-payment`}
						title='Histórico de Pagamentos'
						component={SalesHistoryPayment}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.SalesHistoryPayment}
						id='SalesHistoryPayment'
					/>
					<EnhancedRoute
						path={`${path}/sales-history/:id`}
						title='Detalhes da Venda'
						component={SaleDetails}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.SaleDetails}
						id='SaleDetails'
					/>
					<EnhancedRoute
						path={`${path}/payments-history`}
						title='Histórico de Liquidação'
						component={PaymentHistory}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.PaymentsHistory}
						id='PaymentsHistory'
					/>
					<EnhancedRoute
						path={`${path}/payments-details/:id`}
						title='Detalhes de Pagamentos'
						component={PaymentDetails}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.PaymentDetails}
						id='PaymentDetails'
					/>
					<EnhancedRoute
						path={`${path}/myData`}
						title='Meus Dados'
						component={MyData}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.MyData}
						id='MyData'
					/>
					<EnhancedRoute
						path={`${path}/plan`}
						title='Meu Plano'
						component={MyPlan}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.MyPlan}
						id='MyPlan'
					/>
					<EnhancedRoute
						path={`${path}/customizacao`}
						title='Meu Plano'
						component={Customization}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Customization}
						id='Customization'
					/>
					<EnhancedRoute
						path={`${path}/usuarios`}
						title='Usuários'
						component={Users}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Users}
						id='Users'
					/>
					<EnhancedRoute
						path={`${path}/backoffice/usuarios`}
						title='Usuários Backoffice'
						component={BackofficeUsers}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.BackofficeUsers}
						id='BackofficeUsers'
					/>
					<EnhancedRoute
						exact
						path={`${path}/estabelecimentos`}
						title='Estabelecimentos'
						component={Sellers}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Sellers}
						id='Sellers'
					/>
					<EnhancedRoute
						path={`${path}/estabelecimentos/criar`}
						title='Criar Estabelecimento'
						component={CreateSeller}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.CreateSeller}
						id='Sellers'
					/>
					<EnhancedRoute
						exact
						path={`${path}/estabelecimentos/:id`}
						title='Estabelecimentos'
						component={EditSeller}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Sellers}
						id='Sellers'
					/>
					<EnhancedRoute
						exact
						path={`${path}/estabelecimentos/:id/credenciais`}
						title='Credenciais Estabelecimento'
						component={SellerCredentials}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.SellerCredentials}
						id='SellerCredentials'
					/>
					<EnhancedRoute
						exact
						path={`${path}/estabelecimentos/:id/historico`}
						title='Histórico de Alterações'
						component={SellerHistoryChanges}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.SellerChangesHistory}
						id='SellerChangesHistory'
					/>
					<EnhancedRoute
						path={`${path}/backoffice/pos`}
						title='Pos Backoffice'
						component={BackofficePOS}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.BackofficePos}
						id='BackofficePos'
					/>
					<EnhancedRoute
						path={`${path}/planos-de-custo/criar`}
						title='Criar Planos de Custo'
						component={CreateCostPlan}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.CreateCostPlans}
						id='CreateCostPlans'
					/>
					<EnhancedRoute
						exact
						path={`${path}/planos-de-custo`}
						title='Planos de Custo'
						component={CostPlans}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.CostPlans}
						id='CostPlans'
					/>
					<EnhancedRoute
						path={`${path}/planos-de-custo/:id`}
						title='Detalhes do Plano de Custo'
						component={CostPlanDetails}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.CostPlanDetails}
						id='CostPlanDetails'
					/>
					<EnhancedRoute
						exact
						path={`${path}/planos-de-venda`}
						title='Planos de Venda'
						component={SalesPlan}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.SalesPlan}
						id='SalesPlan'
					/>
					<EnhancedRoute
						path={`${path}/planos-de-venda/criar`}
						title='Criar Plano de Venda'
						component={CreateSalePlan}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.CreateSalesPlan}
						id='CreateSalesPlan'
					/>
					<EnhancedRoute
						path={`${path}/planos-de-venda/:id`}
						title='Detalhes do Planos de Venda'
						component={SalesPlanDetails}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.SalesPlanDetails}
						id='SalesPlanDetails'
					/>
					<EnhancedRoute
						path={`${path}/venda/link-protesto`}
						title='Link de Protesto'
						component={ProtestLinkSale}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.SalesPlan}
						id='ProtestLink'
					/>
					<EnhancedRoute
						exact
						path={`${path}/protocolos`}
						title='Protocolos'
						component={Protocols}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Protocols}
						id='Protocols'
					/>
					<EnhancedRoute
						exact
						path={`${path}/protocolos/:id`}
						title='Editar Protocolo'
						component={EditProtocol}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Protocols}
						id='Protocols'
					/>
					<EnhancedRoute
						exact
						path={`${path}/historico-de-protocolos`}
						title='Histórico'
						component={ProtocolsHistory}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.ProtocolsHistory}
						id='ProtocolsHistory'
					/>
					<EnhancedRoute
						exact
						path={`${path}/personalizacao-de-protocolos`}
						title='Personalização'
						component={ProtocolsCustom}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.ProtocolsCustom}
						id='ProtocolsCustom'
					/>
					<EnhancedRoute
						path={`${path}/permissoes`}
						title='Permissões'
						component={Roles}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Roles}
						id='Roles'
					/>
					<EnhancedRoute
						path={`${path}/shipping-return/upload`}
						title='Enviar Arquivo Retorno'
						component={UploadRetFile}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.UploadRetFile}
						id='UploadRetFile'
					/>
					<EnhancedRoute
						exact
						path={`${path}/merchants`}
						title='Merchants'
						component={Merchants}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Merchants}
						id='Merchants'
					/>
					<EnhancedRoute
						path={`${path}/merchants/criar`}
						title='Criar Merchant'
						component={CreateMerchant}
						action={PermissionActionsEnum.Create}
						subject={PermissionSubjectEnum.Merchants}
						id='CreateMerchants'
					/>
					<EnhancedRoute
						exact
						path={`${path}/merchants/:id`}
						title='Editar Merchant'
						component={EditMerchant}
						action={PermissionActionsEnum.Update}
						subject={PermissionSubjectEnum.Merchants}
						id='UpdateMerchants'
					/>
					<EnhancedRoute
						path={`${path}/merchant/usuarios`}
						title='Usuários Merchant'
						component={MerchantUsers}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.MerchantUsers}
						id='MerchantUsers'
					/>
					<EnhancedRoute
						exact
						path={`${path}/personalizacao-checkout`}
						title='Personalização de Checkouts'
						component={CheckoutsCustomization}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.CheckoutsCustomization}
						id='CheckoutsCustomization'
					/>
					<EnhancedRoute
						exact
						path={`${path}/personalizacao-emails`}
						title='Personalização'
						component={EmailsCustom}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.EmailsCustom}
						id='EmailsCustom'
					/>
					<EnhancedRoute
						exact
						path={`${path}/checkout-test`}
						title='Checkout Teste'
						component={PaymentCheckoutTest}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.PaymentCheckoutTest}
						id='PaymentCheckoutTest'
					/>
					<EnhancedRoute
						exact
						path={`${path}/personalizacao-relatorios`}
						title='Personalização de Relatórios'
						component={ReportCustomization}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.ReportCustomization}
						id='ReportCustomization'
					/>
					<EnhancedRoute
						exact
						path={`${path}/alertas-mensais`}
						title='Alertas Mensais'
						component={MonthlyTransactionsAlerts}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.MonthlyTransactionsAlerts}
						id='MonthlyTransactionsAlerts'
					/>
					<EnhancedRoute
						exact
						path={`${path}/responsaveis`}
						title='Responsáveis'
						component={Responsibles}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Responsibles}
						id='Responsibles'
					/>
					<EnhancedRoute
						exact
						path={`${path}/responsaveis/criar`}
						title='Criar Responsável'
						component={CreateResponsible}
						action={PermissionActionsEnum.Create}
						subject={PermissionSubjectEnum.Responsibles}
						id='CreateResponsible'
					/>
					<EnhancedRoute
						exact
						path={`${path}/responsaveis/:id`}
						title='Editar Responsável'
						component={EditResponsible}
						action={PermissionActionsEnum.Update}
						subject={PermissionSubjectEnum.Responsibles}
						id='UpdateResponsibles'
					/>
					<EnhancedRoute
						exact
						path={`${path}/metas`}
						title='Metas'
						component={Goals}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Goals}
						id='Goals'
					/>
					<EnhancedRoute
						exact
						path={`${path}/payment-count-history`}
						title='Histórico de Pagamento de Contas'
						component={BillPaymentHistory}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.BillPaymentHistory}
						id='BillPaymentHistory'
					/>
					<EnhancedRoute
						exact
						path={`${path}/alertas-atribuicao`}
						title='Alertas Atribuição'
						component={AssignmentTransactionAlerts}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.AssignmentTransactionAlerts}
						id='AssignmentTransactionAlerts'
					/>
					<EnhancedRoute
						path={`${path}/alertas-atribuicao/:id`}
						title='Alertas Atribuição Detalhes'
						component={AssignmentTransactionAlertDetails}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.AssignmentTransactionAlerts}
						id='AssignmentTransactionAlertDetails'
					/>
					<EnhancedRoute
						exact
						path={`${path}/cadastrar-notificacoes`}
						title='Cadastrar Notificações'
						component={Notifications}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Notifications}
						id='CreateNotifications'
					/>
					<EnhancedRoute
						exact
						path={`${path}/notificacoes`}
						title='Visualizar Notificações'
						component={NotificationsPanel}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.Notifications}
						id='SearchNotifications'
					/>
					<EnhancedRoute
						exact
						path={`${path}/pedido-bobinas`}
						title='Pedido de Bobinas'
						component={PaperRollOrder}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.PaperRollOrder}
						id='PaperRollOrder'
					/>
					<EnhancedRoute
						exact
						path={`${path}/dda`}
						title='Localizar DDA'
						component={FindDda}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.FindDda}
						id='FindDda'
					/>
					<EnhancedRoute
						exact
						path={`${path}/pos-password`}
						title='Senha Pos Android'
						component={PosPassword}
						action={PermissionActionsEnum.Read}
						subject={PermissionSubjectEnum.PosPassword}
						id='PosPassword'
					/>
					<EnhancedRoute title='Página não encontrada' component={PageNotFound} id='PageNotFound' />
				</Switch>
			</Box>
		</SellerProvider>
	);
};

export default Admin;
