import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Text } from '@chakra-ui/react';
import { GetSellerChangesCollectionNameEnum, SellersChangesHistoryApi } from 'clients';
import Paper from 'containers/Paper';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getApiAuthConfig } from 'services/api.service';
import Loader from '../../../containers/Loader';
import SampleTable from './SampleTable';

const SellerHistoryChanges: React.FC = () => {
	const { id } = useParams();
	const { addToast } = useToasts();
	const apiConfig = getApiAuthConfig();
	const sellersChangesHistoryApi = new SellersChangesHistoryApi(apiConfig);

	const [pagination, setPagination] = React.useState({ currentPage: 1, limit: 10 });
	const [totalPages, setTotalPages] = React.useState(1);
	const [sellerChangesHistory, setSellerChangesHistory] = React.useState<any>(undefined);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const getSellerChangesHistory = async () => {
		setIsLoading(true);
		try {
			const response = await sellersChangesHistoryApi.getSellerChanges({
				collectionName: [GetSellerChangesCollectionNameEnum.Sellers, GetSellerChangesCollectionNameEnum.SellerRisks],
				documentId: id,
				currentPage: pagination.currentPage,
				limit: pagination.limit,
			});
			setSellerChangesHistory(response.results);
			setTotalPages(response.total_pages);
		} catch (error) {
			addToast('Erro ao buscar o histórico de alterações do estabelecimento', {
				appearance: 'error',
				autoDismiss: true,
			});
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		getSellerChangesHistory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination]);

	return (
		<Flex direction='column'>
			<Loader isOpen={isLoading} />
			<Flex direction={['column', 'column', 'row']} justify='space-between'>
				<Breadcrumb separator={<ChevronRightIcon />} color='darkGrey' mb='4'>
					<BreadcrumbItem>
						<Text fontSize='md'>Resumo</Text>
					</BreadcrumbItem>

					<BreadcrumbItem>
						<BreadcrumbLink fontSize='md' href='/admin/estabelecimentos'>
							Estabelecimentos
						</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<Text fontSize='md' fontWeight='semibold'>
							Histórico de Alterações
						</Text>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>
			<Paper id='sellers-changes-history-paper' p={6}>
				{sellerChangesHistory && (
					<SampleTable data={sellerChangesHistory} pagination={pagination} setPagination={setPagination} totalPages={totalPages} />
				)}
			</Paper>
		</Flex>
	);
};

export default SellerHistoryChanges;
