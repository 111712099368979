import React from 'react';

import { HeaderTitle, CellContent, SimpleTable, HeaderTitleProps, CellContentProps } from '../../../containers/SimpleTable';

import { sellerStatus } from 'services/enums.service';
import { maskMoney } from '../../../services/masks.service';
import { Icon, Button, Flex, Tooltip } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi';
import { BsToggleOff } from 'react-icons/bs';
import { IoKeyOutline, IoClipboardOutline } from 'react-icons/io5';
import { useAuth } from 'contexts/AuthProvider';
import Text from '../../../components/Text';
import { PermissionSubjectEnum } from '../../../clients';

const HeaderTitleStyled: React.FC<HeaderTitleProps> = (props) => {
	return (
		<HeaderTitle {...props} color='black' justifyContent='flex-start' fontSize='sm' fontWeight='bold'>
			{props.children}
		</HeaderTitle>
	);
};

const CellContentStyled: React.FC<CellContentProps> = (props) => {
	return (
		<CellContent {...props} color='primary' justifyContent='flex-start' fontSize='sm' fontWeight='semibold'>
			{props.children}
		</CellContent>
	);
};

export default ({ data, setPagination, pagination, totalPages, openActivationModal }) => {
	const columns = React.useMemo(() => {
		const history = useHistory();
		const { isBackoffice, user } = useAuth();

		let cols: any = [];

		const goToEditPage = (sellerId: string) => {
			history.push(`/admin/estabelecimentos/${sellerId}`);
		};

		const goToCredentialsPage = (sellerId: string) => {
			history.push(`/admin/estabelecimentos/${sellerId}/credenciais`);
		};

		const goToHistoryPage = (sellerId: string) => {
			history.push(`/admin/estabelecimentos/${sellerId}/historico`);
		};

		return cols.concat([
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='id-title' column={column}>
						ID
					</HeaderTitleStyled>
				),
				accessor: 'id',
				Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitle id='edit-seller-button-title' column={column}>
						Ações
					</HeaderTitle>
				),
				accessor: 'edit-seller-button',
				Cell: ({ cell: { row, column } }) => (
					<CellContent id={`${row.index}-${column.id}-cell`}>
						<Flex>
							<Tooltip label='Editar Estabelecimentos'>
								<Button
									id={`${row.index}-${column.id}-edit-button`}
									background={'transparent'}
									onClick={() => {
										goToEditPage(row.values.id);
									}}
								>
									<Icon id={`${row.index}-${column.id}-edit-icon`} as={FiEdit} boxSize={5} />
								</Button>
							</Tooltip>
							<Tooltip label='Credenciais'>
								<Button
									id={`${row.index}-${column.id}-credentials-icon`}
									background={'transparent'}
									onClick={() => {
										goToCredentialsPage(row.values.id);
									}}
								>
									<Icon id={`${row.index}-${column.id}-credentials-icon`} as={IoKeyOutline} boxSize={5} />
								</Button>
							</Tooltip>
							{user?.role?.permissions?.some((obj) => obj?.subject === PermissionSubjectEnum.SellerChangesHistory) && (
								<Tooltip label='Histórico de Alterações'>
									<Button
										background={'transparent'}
										onClick={() => {
											goToHistoryPage(row.values.id);
										}}
									>
										<Icon as={IoClipboardOutline} boxSize={5} />
									</Button>
								</Tooltip>
							)}
						</Flex>
					</CellContent>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='status-title' column={column}>
						Status
					</HeaderTitleStyled>
				),
				accessor: 'status',
				Cell: ({ cell: { row, value, column } }) => (
					<CellContentStyled id={`${row.index}-${column.id}-cell`} display={`flex`} flexDirection={`row`} onClick={() => {}}>
						<Flex>
							<Text id={`${row.index}-${column.id}-text`}>{sellerStatus[value] ?? 'N/A'}</Text>
							{value === 'activation_sent' && isBackoffice && (
								<Tooltip label='Ativar' placement='top'>
									<Button
										id={`${row.index}-${column.id}-button`}
										background={'transparent'}
										onClick={() => {
											openActivationModal(row.values.id);
										}}
									>
										<Icon id={`${row.index}-${column.id}-icon`} as={BsToggleOff} boxSize={5} />
									</Button>
								</Tooltip>
							)}
						</Flex>
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='name-title' column={column}>
						Nome
					</HeaderTitleStyled>
				),
				accessor: 'name',
				Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>{value}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='created-at-title' column={column}>
						Data de Criação
					</HeaderTitleStyled>
				),
				accessor: 'created_at',
				Cell: ({ cell: { value, row, column } }) => (
					<CellContentStyled id={`${row.index}-${column.id}-cell`}>
						{new Date(value)?.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}
					</CellContentStyled>
				),
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='total-batch-title' column={column} textAlign='center'>
						Volume Transacionado Total
					</HeaderTitleStyled>
				),
				accessor: 'totalBatch',
				Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
			{
				Header: ({ column }) => (
					<HeaderTitleStyled id='latet-settlement-title' column={column} textAlign='center'>
						Volume Transacionado em 30 dias
					</HeaderTitleStyled>
				),
				accessor: 'latestSettlement',
				Cell: ({ cell: { value, row, column } }) => <CellContentStyled id={`${row.index}-${column.id}-cell`}>R$ {maskMoney(value / 100)}</CellContentStyled>,
			},
		]);
	}, [openActivationModal]);

	return (
		<SimpleTable
			columns={columns}
			data={data}
			setPagination={setPagination}
			pagination={pagination}
			totalPages={totalPages}
			pl={2}
			variant='unstyled'
			maxHeightTableWrapper='42vh'
		/>
	);
};
