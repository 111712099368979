import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';
import Text from '../../../components/Text';
import { GetNotificationReadStatus } from '../interfaces/notificationReadStatus';

interface NotificationTabsProps {
	selectedTabIndex: number;
	setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
	filteredNotifications: GetNotificationReadStatus[];
	unreadNotifications: GetNotificationReadStatus[];
	selectedNotification: GetNotificationReadStatus | null;
	handleNotificationClick: (notification: GetNotificationReadStatus) => void
}

const NotificationTabs: React.FC<NotificationTabsProps> = ({
	selectedTabIndex,
	setSelectedTabIndex,
	filteredNotifications,
	unreadNotifications,
	selectedNotification,
	handleNotificationClick,
}) => {
	return (
		<Tabs variant='line' index={selectedTabIndex} onChange={(index) => setSelectedTabIndex(index)}>
			<TabList>
				<Tab id='notifications-list-all' flex='1' _focus={{ outline: 'none' }}>
					Todas ({filteredNotifications.length})
				</Tab>
				<Tab id='notifications-list-unread' flex='1' _focus={{ outline: 'none' }}>
					Não lidas ({unreadNotifications.length})
				</Tab>
			</TabList>

			<TabPanels>
				<TabPanel paddingX='0px'>
					<VStack
						align='stretch'
						overflowY='auto'
						maxHeight='400px'
						css={{
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							'&': {
								scrollbarWidth: 'none',
								msOverflowStyle: 'none',
							},
						}}
					>
						{filteredNotifications.length === 0 ? (
							<Box p={5} textAlign='center' color='gray.500'>
								Não há notificações para exibir.
							</Box>
						) : (
							filteredNotifications.map((notification) => (
								<Box
									key={notification.id}
									bg={selectedNotification?.id === notification.id ? 'white' : notification.is_read ? '#FAFAFA' : 'gray.200'}
									cursor='pointer'
									onClick={() => handleNotificationClick(notification)}
									_hover={{ bg: 'gray.200' }}
									display='flex'
									alignItems='flex-start'
									p={5}
								>
									{!notification.is_read && <Box width='10px' height='10px' borderRadius='full' bg='red.500' mr='3' />}
									<Box flex='1'>
										<Text id='notification-panel-tabs-title-text' textAlign='justify' fontWeight='bold' mb='1' noOfLines={3}>
											{notification.title}
										</Text>
										<Text id='notification-panel-tabs-date-text' textAlign='justify' fontSize='sm' color='gray.500' mb='1' noOfLines={2}>
											{format(new Date(notification.created_at), 'dd/MM/yyyy HH:mm')}
										</Text>
										<Text id='notification-panel-tabs-description-text' textAlign='justify' fontSize='sm' noOfLines={1}>
											{notification.description}
										</Text>
									</Box>
								</Box>
							))
						)}
					</VStack>
				</TabPanel>
				<TabPanel paddingX='0px'>
					<VStack
						align='stretch'
						overflowY='auto'
						maxHeight='400px'
						css={{
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							'&': {
								scrollbarWidth: 'none',
								msOverflowStyle: 'none',
							},
						}}
					>
						{unreadNotifications.length === 0 ? (
							<Box p={5} textAlign='center' color='gray.500'>
								Não há notificações para exibir.
							</Box>
						) : (
							unreadNotifications.map((notification) => (
								<Box
									key={notification.id}
									bg={selectedNotification?.id === notification.id ? 'white' : notification.is_read ? '#FAFAFA' : 'gray.200'}
									cursor='pointer'
									onClick={() => handleNotificationClick(notification)}
									_hover={{ bg: 'gray.200' }}
									display='flex'
									alignItems='flex-start'
									p={5}
								>
									{!notification.is_read && <Box width='10px' height='10px' borderRadius='full' bg='red.500' mr='3' />}
									<Box flex='1'>
										<Text id='notification-panel-tabs-unread-title-text' textAlign='justify' fontWeight='bold' mb='1' noOfLines={3}>
											{notification.title}
										</Text>
										<Text id='notification-panel-tabs-unread-date-text' textAlign='justify' fontSize='sm' color='gray.500' mb='1' noOfLines={2}>
											{format(new Date(notification.created_at), 'dd/MM/yyyy HH:mm')}
										</Text>
										<Text id='notification-panel-tabs-unread-description-text' textAlign='justify' fontSize='sm' noOfLines={1}>
											{notification.description}
										</Text>
									</Box>
								</Box>
							))
						)}
					</VStack>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

export default NotificationTabs;
