import { Flex } from '@chakra-ui/react';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';

export const absorbCostsAndNoPaymentRenderer = (value, translatedProp, maskFunction) => {
	const entries = Object.entries(value).filter(([key]) => !key.startsWith('_t'));

	const renderArrayValue = (arr) => {
		if (!arr.length) {
			return <Flex>Nenhum item selecionado</Flex>;
		}

		return arr.map((item, index) => (
			<Flex key={index} flexDirection='row' alignItems='center' display='inline-flex'>
				{maskFunction && typeof maskFunction === 'function'
					? maskFunction(item)
					: typeof item === 'boolean'
					? item
						? 'Sim'
						: 'Não'
					: item === null || item === '' || item === 0
					? 'Nenhum item selecionado'
					: item}

				{index < arr.length - 1 && (
					<Flex px='10px'>
						<FaArrowRight />
					</Flex>
				)}
			</Flex>
		));
	};

	return (
		<Flex flexDirection='column'>
			<Flex fontWeight='bold' mr='10px'>
				{translatedProp}:
			</Flex>
			{!entries.length ? (
				<Flex>
					<Flex>Sem dados</Flex>
				</Flex>
			) : (
				entries.map(([key, value]) => {
					if (/^_\d+$/.test(key)) {
						return (
							<Flex key={key}>
								<Flex mr="5px">Removido:</Flex>
								{Array.isArray(value) && value.length > 0
									? renderArrayValue([value[0]])
									: 'Sem dados'}
							</Flex>
						);
					}
				
					if (Array.isArray(value)) {
						return (
							<Flex key={key}>
								<Flex mr='5px'>Adicionado:</Flex>
								{renderArrayValue(value)}
							</Flex>
						);
					} else {
						return <Flex key={key}>{`Item ${key}: ${value ? value : 'Sem dados'}`}</Flex>;
					}
				})
			)}
		</Flex>
	);
};
